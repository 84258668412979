import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Container from 'react-bootstrap5/Container';
import Navbar from 'react-bootstrap5/Navbar';
import Nav from 'react-bootstrap5/Nav';
import { useDebounce, useDebounceCallback } from '@react-hook/debounce';

import { Icon } from '../../Icon';
import { useWindowSize } from '../../../hooks';

interface BrochureSiteHeaderProps {
  brand: React.ReactSVGElement;
  platformTitle?: string;
  mainMenu: Record<string, unknown>;
}

function MenuLink({
  menuText,
  linkPageUrl,
  linkUrl,
  urlAppend,
  handle,
  ...other
}) {
  let anchor = (
    <Nav.Item className={classNames('nav-item')}>
      <Nav.Link href={linkPageUrl || linkUrl}>{menuText}</Nav.Link>
    </Nav.Item>
  );

  // Wrap 'Page' links with router
  if (linkPageUrl) {
    anchor = <Link href={linkPageUrl}>{anchor}</Link>;
  }

  return anchor;
}

function MainMenu({ menuItems }) {
  const menuLinks = menuItems.map((menuItem) => {
    return <MenuLink key={menuItem.id} {...menuItem} />;
  });

  return menuLinks;
}

const BrochureSiteHeader: React.FC<BrochureSiteHeaderProps> = ({
  brand: Brand = null,
  platformTitle = 'penfold',
  mainMenu,
}) => {
  const autoHideMaxHeight = 768;
  const autoHideMinWidth = 768;
  const autoHideMinScroll = 200; // Must be at least height of navbar itself
  const [showHeader, setShowHeader] = useDebounce(true);
  const [scrollPos, setScrollPos] = useState(0);
  const navBarRef = useRef(null);
  const windowSize = useWindowSize();

  // Observe scroll to control `showHeader` when screen short enough to auto-hide
  useEffect(() => {
    const handleScroll = () => {
      const scrollTopPx = document.body.getBoundingClientRect().top;
      let autoHideOnScroll = false;

      if (windowSize.height && windowSize.width) {
        autoHideOnScroll =
          windowSize.height < autoHideMaxHeight &&
          windowSize.width > autoHideMinWidth;
      }

      setScrollPos(scrollTopPx);

      if (autoHideOnScroll) {
        setShowHeader(
          scrollTopPx > -1 * autoHideMinScroll || scrollTopPx > scrollPos
        ); // show if scrolling up
      } else {
        setShowHeader(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [
    windowSize.height,
    windowSize.width,
    autoHideMaxHeight,
    scrollPos,
    setScrollPos,
    setShowHeader,
  ]);

  return (
    <Navbar
      ref={navBarRef}
      // bg={scrollPos >= 0 ? 'primaryToneDarker' : 'white'}
      // variant={scrollPos >= 0 ? 'dark' : 'light'}
      bg={'white'}
      variant={'light'}
      expand="md"
      className={classNames(
        'py-2',
        'px-2',
        scrollPos >= 0 ? 'shadow-none' : 'shadow',
        'sticky-top'
      )}
      style={{
        visibility: showHeader ? 'visible' : 'hidden',
        transition: 'all 400ms',
        transform: showHeader ? 'translateY(0%)' : 'translateY(-100%)',
        backdropFilter: `blur(2px)`,
        '--bs-bg-opacity': scrollPos >= 0 ? '1' : '0.85',
      }}
    >
      <Container fluid className={classNames('container-lg')}>
        <Link href="/">
          <Navbar.Brand
            // as={Link}
            href="/"
            title={`${platformTitle}`}
            aria-label={`${platformTitle}`}
          >
            {Brand && (
              <Brand
                height="38"
                className={classNames(
                  Brand.className,
                  'd-inline-block',
                  'align-middle'
                )}
              />
            )}
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle />
        <Navbar.Collapse className={'navbar-collapse'}>
          <Nav
            className={classNames(
              'navbar-nav',
              'mb-lg-0',
              'flex-fill',
              'justify-content-start'
            )}
          >
            {mainMenu && <MainMenu menuItems={mainMenu.menuItems} />}
            {/* <Nav.Item className={classNames('nav-item', 'ms-auto', 'ml-auto')}>
              <Nav.Link
                href="tel:18885555555"
                className={classNames('nav-link', 'btn-link', 'btn-circle')}
              >
                <Icon
                  name="Phone"
                  title={'Call us at 1-888-555-5555'}
                  size={1.15}
                  alignText={true}
                  className={classNames('pe-1')}
                />
                CONTACT US
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export { BrochureSiteHeader };
