import React, { useState, useMemo, useEffect, useRef } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Breadcrumb from 'react-bootstrap5/Breadcrumb';
import Collapse from 'react-bootstrap5/Collapse';
import LoadingBar from 'react-top-loading-bar';

import { BrochureSiteHeader } from '../BrochureSiteHeader';
import { BrochureSiteFooter } from '../BrochureSiteFooter';
import { SiteContext } from '../../../contexts/SiteContext';

import { LayoutContainer } from '../LayoutContainer';
// import { PageSwitchTransition } from './PageTransition';

// import { EventServiceProvider } from '@penfold/react-services';

function BreadcrumbLink({ href, children, ...other }) {
  return (
    <Link href={href}>
      <a {...other} className={classNames('text-decoration-none')}>
        {children}
      </a>
    </Link>
  );
}

interface CatalogSiteLayoutProps {
  brand: React.ReactSVGElement;
  applicationTitle: string;
  mainMenu: Record<string, unknown>[];
  breadcrumb: Record<string, unknown>[];
  footerMenu: Record<string, unknown>[];
  children: React.ReactNode;
}

// TODO: Extract props/data from rendered template
const CatalogSiteLayout: React.FC<CatalogSiteLayoutProps> = ({
  brand,
  applicationTitle,
  mainMenu,
  breadcrumb,
  footerMenu,
  children,
  ...other
}) => {
  const router = useRouter();
  const [initPath, setInitPath] = useState();
  const [showPath, setShowPath] = useState(null);
  const [showChildren, setShowChildren] = useState(null);
  const loadingBarRef = useRef(null);
  const showBreadcrumb = breadcrumb?.length > 0;

  // NOTE: The following code may be refactored/simplified, and should be
  // extracted. Its original purpose was to attempt to load a lazily-loaded
  // target page before transitioning. A better strategy for lazy-loading
  // would be to intentionally display a placeholder component immediately
  // that is designed to indicate loading occuring (i.e. spinner + message)

  // Initialize show/init path
  useMemo(() => {
    if (!showPath) {
      setShowPath(router.asPath);
      setInitPath(router.asPath);
    }
  }, [router.asPath, showPath, setShowPath, setInitPath]);

  // initialize `showChildren`, changes value when show/init path mismatch
  useMemo(() => {
    if (!showChildren) {
      setShowChildren(children);
      return;
    }

    // Needs ADDITIONAL condition check on routechange complete?
    if (children !== showChildren && showPath !== initPath) {
      setShowChildren(children);
      setInitPath(showPath); // Re-synchronize
    }
  }, [children, showChildren, setShowChildren, showPath, initPath]);

  // observe router state to trigger router bar, visible children
  useEffect(() => {
    const routeChangeStart = (url, options) => {
      loadingBarRef.current?.continuousStart();
    };

    const routeChangeComplete = (url, options) => {
      loadingBarRef.current?.complete();
      setShowPath(url);
    };

    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeComplete);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, [router, showPath, setShowPath, initPath, setInitPath]);

  return (
    <SiteContext.Provider value={{ mainMenu, footerMenu, applicationTitle }}>
      {/* <EventServiceProvider url={websocketUrl}> */}
      <Head>
        <meta charSet="utf-8" />
        <title>{applicationTitle}</title>
      </Head>
      <a href="#content-start" className="sr-only visually-hidden-focusable">
        Skip to content
      </a>
      <LoadingBar
        ref={loadingBarRef}
        color={`var(--bs-info, blue)`}
        height={3}
        shadow={false}
        loaderSpeed={250}
        waitingTime={150}
      />
      <BrochureSiteHeader
        brand={brand}
        platformTitle={applicationTitle}
        mainMenu={mainMenu}
      />
      <div
        className={classNames(
          'bg-light',
          showBreadcrumb && 'border-bottom',
          'border-1',
          showBreadcrumb && 'border-light'
        )}
      >
        <Collapse
          in={showBreadcrumb}
          dimension="height"
          timeout={showBreadcrumb ? 500 : 150}
        >
          <div
            className={classNames('m-0', 'p-0')}
            style={{
              transition: showBreadcrumb
                ? 'height 150ms ease-out 350ms'
                : 'height 150ms ease-out 0ms',
            }}
          >
            <LayoutContainer>
              <Breadcrumb
                className={classNames('py-1', 'my-0')}
                listProps={{
                  className: classNames('mb-0'),
                  style: { minHeight: '2.5ex' },
                }}
              >
                {breadcrumb?.map((crumb) => {
                  const crumbProps = {
                    key: crumb.id,
                    href: crumb.url || null,
                    children: crumb.title,
                    active: crumb.active,
                  };

                  return (
                    <Breadcrumb.Item linkAs={BreadcrumbLink} {...crumbProps} />
                  );
                })}
              </Breadcrumb>
            </LayoutContainer>
          </div>
        </Collapse>
      </div>
      <div
        {...other}
        className={classNames(
          'd-flex',
          'flex-row',
          'flex-grow-1',
          'align-items-stretch',
          'overflow-auto'
        )}
      >
        <div
          id={'scope-app'}
          className={classNames('main-wrapper', 'overflow-hidden')}
        >
          <div
            className={classNames(
              'd-flex',
              'flex-column',
              'flex-fill',
              'overflow-hidden'
            )}
          >
            <div id="content-start" className="sr-only visually-hidden" />
            {/* <PageSwitchTransition location={initPath}> */}
            {showChildren}
            {/* </PageSwitchTransition> */}
          </div>
        </div>
      </div>
      <BrochureSiteFooter
        brand={brand}
        platformTitle={applicationTitle}
        footerMenu={footerMenu}
      />
      {/* </EventServiceProvider> */}
    </SiteContext.Provider>
  );
};

export default CatalogSiteLayout;
