import React from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import { Layout } from '../components/Layout';
import '../styles/index.scss';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
  // This approach always falls back to default because [...path] has no layout
  const getLayout =
    Component.getLayout ??
    ((page) => (
      <Layout
        breadcrumb={pageProps.componentProps?.breadcrumb || null}
        mainMenu={pageProps.componentProps?.mainMenu || null}
        footerMenu={pageProps.componentProps?.footerMenu || null}>
        {page}
      </Layout>
    ));

  return getLayout(<Component {...pageProps} />);
}

export default App;
