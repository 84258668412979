import React from 'react';

import { CatalogSiteLayout } from '@penfold/react-components';
// import { EventService } from '@penfold/react-services';
// import { OpenApiDataProvider } from '@penfold/react-services';
// import { ResourceRegistry } from '@penfold/react-services';

import { ReactComponent as Logo } from '../../public/images/svgr/hex-logo.svg';

// import './styles.global.css';

// TODO: Extract definitions to configuration, static or runtime (?)
// let wsSchema = 'wss';
// if (typeof window !== 'undefined' && window.location.protocol === 'http:') {
//   wsSchema = 'ws';
// }
// const websocketUrl = `${wsSchema}://localhost:8000/ws/`;
// const dataProvider = new OpenApiDataProvider('http://localhost:8000/api/openapi.json');

const Layout = ({ children, mainMenu, breadcrumb, footerMenu, ...other }) => {
  return (
    <CatalogSiteLayout
      {...other}
      brand={Logo}
      applicationTitle={process.env.applicationTitle} // FIXME
      mainMenu={mainMenu}
      footerMenu={footerMenu}
      breadcrumb={breadcrumb}>
      {children}
    </CatalogSiteLayout>
  );
};

export { Layout };
