import React, { createContext, useContext } from "react";

const defaultContext = {};

const SiteContext = createContext(defaultContext);

const useSiteContext = () => {
    return useContext(SiteContext);
}

export { SiteContext, defaultContext, useSiteContext };
