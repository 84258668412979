import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import Container from 'react-bootstrap5/Container';
import Col from 'react-bootstrap5/Col';
import Row from 'react-bootstrap5/Row';
import Nav from 'react-bootstrap5/Nav';
import { mdiNoteMultipleOutline } from '@mdi/js';

function MenuGroupHeader({ children }) {
  return (
    <div className={classNames(['h6', 'px-1', 'text-primaryToneLight'])}>
      {children}
    </div>
  );
}

function MenuLink({ href, isPage = true, className, children, ...other }) {
  let anchor = (
    <Nav.Link
      {...other}
      className={classNames('text-white', 'p-1', className)}
      href={href}
    >
      {children}
    </Nav.Link>
  );

  // Wrap 'Page' links with router
  if (isPage) {
    anchor = <Link href={href}>{anchor}</Link>;
  }

  return anchor;
}

function FooterMenu({ title, children }) {
  return (
    <>
      <MenuGroupHeader>{title}</MenuGroupHeader>
      <Nav className={classNames(['flex-column', 'gx-0', 'mb-4'])}>
        {children}
      </Nav>
    </>
  );
}

function FooterMenuGroup({ title, heading, handle, menuItems }) {
  if (!menuItems) return null;

  const links = menuItems.map((menuItem) => {
    return (
      <MenuLink
        key={menuItem.id}
        href={menuItem.linkPageUrl || menuItem.linkUrl}
        isPage={menuItem.linkPageUrl}
      >
        {menuItem.menuText}
      </MenuLink>
    );
  });

  return <FooterMenu title={title}>{links}</FooterMenu>;
}

function BrochureSiteFooter({
  brand: Brand = null,
  platformTitle = 'penfold',
  footerMenu = {},
}) {
  return (
    <footer
      className={classNames('bg-dark', 'text-light', 'py-5', 'px-2')}
      style={{ fontSize: '0.85rem' }}
    >
      <Container className={classNames()}>
        <Row className={classNames('pb-3')}>
          <Col>
            {Brand && (
              <Brand
                height="32"
                title={platformTitle}
                aria-label={platformTitle}
              />
            )}
          </Col>
        </Row>
      </Container>

      <Container className={classNames()}>
        <Row className={classNames('py-3', 'gy-0')}>
          <Col md={4} lg={5} className={classNames('mb-4', 'md-mb-0')}>
            <FooterMenuGroup {...footerMenu?.corporate} />

            <p className={classNames('opacity-75')}>
              &copy; Penfold. All rights reserved. The material on this site
              cannot be reproduced or redistributed unless you have obtained
              prior written permission from Penfold.
            </p>
          </Col>
          <Col md={8} lg={7}>
            <Row>
              <Col
                md={4}
                className={classNames('md-mb-0', 'mb-4', 'border-left')}
              >
                <FooterMenuGroup {...footerMenu?.resources} />
              </Col>
              <Col
                md={4}
                className={classNames('md-mb-0', 'mb-4', 'border-left')}
              >
                <FooterMenuGroup {...footerMenu?.takingCourses} />
              </Col>
              <Col
                md={4}
                className={classNames('md-mb-0', 'mb-4', 'border-left')}
              >
                <FooterMenuGroup {...footerMenu?.resources} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export { BrochureSiteFooter };
