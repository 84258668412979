import React from 'react';
import Container from 'react-bootstrap5/Container';
import classNames from 'classnames';

const defaultClassNames = ['container-lg', 'px-4', 'px-lg-3'];

// This visually "Contains" or "constrains" MOST content from filling the full screen
// width. Omit or override if you want your block to fill the available screen, but
// make sure any content within is constrained otherwise it may be illegible on
// wide displays
function LayoutContainer({
  fluid = true,
  className = defaultClassNames,
  children,
}) {
  return (
    <Container fluid={fluid} className={classNames(className)}>
      {children}
    </Container>
  );
}

export { LayoutContainer, defaultClassNames };
